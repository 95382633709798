import config from 'config';

/**
 *
 * @param {String} key
 * @param {String} token
 * @returns
 */
const HeadersServices = (key, token) => {
  const default_headers = {
    'X-App-Token': `Bearer ${token}`,
    Authorization: `Bearer ${token}`,
    'X-Client-Id': 'NUBITOOLS',
    'X-Client-Version': `${config.version}`,
  };
  const services = {
    los: {
      'X-App-Token': `${token}`,
      Authorization: `Bearer ${token}`,
      'X-Client-Id': 'NUBITOOLS',
      'X-Client-Version': `${config.version}`,
    },
    'fund-ops': {
      'X-App-Token': `Bearer ${token}`,
      'X-Client-Id': 'NUBITOOLS',
      'X-Client-Version': `${config.version}`,
    },
    'fund-kyc': {
      'X-App-Token': `${token}`,
      'X-Client-Id': 'NUBITOOLS',
      'X-Client-Version': `${config.version}`,
    },
    fms: {
      Authorization: `Bearer ${token}`,
      'X-Client-Id': 'NUBITOOLS',
      'X-Client-Version': `${config.version}`,
      'Content-Type': 'multipart/form-data',
    },
    support: {
      'X-App-Token': `Bearer ${token}`,
      Authorization: `Bearer ${token}`,
      'X-Client-Id': 'NUBITOOLS',
      'X-Client-Version': `${config.version}`,
    },
    'fund-scoring': {
      'X-App-Token': `${token}`,
      Authorization: `Bearer ${token}`,
      'X-Client-Id': 'NUBITOOLS',
      'X-Client-Version': `${config.version}`,
    },
    auth: {
      Authorization: `Bearer ${token}`,
      'X-Client-Id': 'NUBITOOLS',
      'X-Client-Version': `${config.version}`,
    },
  };
  return services[key] ?? default_headers;
};

export default HeadersServices;
